import styled from 'styled-components'
import { breakpoints } from 'src/utils/styles/global-styles'

export const Section = styled.div`
  background-color: ${(props) => props.theme.blueLight};
  padding: 65px 0px;
  @media (max-width: ${breakpoints.m}px) {
    padding: 60px 0px;
  }
`
export const Text = styled.div`
  color: ${(props) => props.theme.blueDark};
  width: 430px;
  font-family: 'FilsonProBook';
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 26px;
  letter-spacing: 0.09px;
  text-align: left;
  padding: 0px 20px 20px;
  @media (max-width: ${breakpoints.xl}px) {
    width: 400px;
    margin-left: 20px;
  }
  @media (max-width: ${breakpoints.m}px) {
    width: 90%;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.08px;
    margin: 0px 24px 40px;
  }
  @media (max-width: ${breakpoints.s}px) {
    max-width: 100%;
  }
`
export const Container = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  @media (max-width: ${breakpoints.m}px) {
    flex-wrap: ${(props) => props.flexWrap};
  }
  div:nth-child(2) {
    justify-content: left;
  }
`
export const Card = styled.div`
  width: 50%;
  display: flex;
  justify-content: left;
  position: relative;
  flex-direction: row;
  @media (max-width: ${breakpoints.m}px) {
    width: 100%;
  }
  @media (max-width: ${breakpoints.s}px) {
    width: 100%;
  }
`
export const MoneyBackContainer = styled.div`
  width: 110px;
  position: absolute;
  top: 30px;
  @media (max-width: ${breakpoints.xl}px) {
    top: 30px;
  }
  @media (max-width: 1100px) {
    width: 80px;
    top: -35px;
    right: 20px;
  }
  @media (max-width: ${breakpoints.m}px) {
    width: 110px;
    top: 20px;
    right: 150px;
  }
  @media (max-width: 715px) {
    width: 110px;
    right: 90px;
  }
  @media (max-width: 660px) {
    width: 110px;
    right: 50px;
  }
  @media (max-width: 615px) {
    width: 80px;
    right: 40px;
  }
  @media (max-width: ${breakpoints.s}px) {
    display: block;
    width: 80px;
    top: -40px;
    right: 20px;
  }
`
export const Box = styled.div`
  margin-left: -50px;
  max-width: 600px;
  width: 100%;
  @media (max-width: ${breakpoints.m}px) {
    margin-left: -60px;
  }
  @media (max-width: ${breakpoints.m}px) {
    margin-left: -20px;
  }
`
export const FlexBox = styled.div`
  width: 100%;
  display: flex;
  margin-left: 50px;
  justify-content: flex-end !important;
`
