import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import YotpoStars from 'src/components/YotpoStars'
import { graphql, useStaticQuery } from 'gatsby'
import { useTranslation } from 'gatsby-plugin-react-i18next'

import {
  Container,
  TextBox,
  Text,
  CenteredOnMobile,
  Section,
  VideoContainer,
  HeroVideo,
  SubHeader,
  BuyNowLink,
  Item,
  ListItem,
  CheckMark,
  BuyNowAnchor,
  ExternalLink,
} from './styles'
import { BUY_LINK } from '../../utils/constants'
import heroVideo from '../../videos/frame-video.mp4'
import greetingVideo from '../../videos/greetings.mp4'
import Analytics from '../../utils/Analytics'

const Hero = ({ page, title, subheading, ctaText }) => {
  const { t } = useTranslation('common')

  const { check, link } = useStaticQuery(graphql`
    query HeroQuery {
      check: file(relativePath: { eq: "checkmark.png" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: NONE)
        }
      }
      link: file(relativePath: { eq: "external-link.webp" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: NONE)
        }
      }
    }
  `)

  const HERO_VIDEO_OVERRIDE = {
    greetings: greetingVideo,
  }
  const AMAZON_LINK =
    'https://www.amazon.com/Skylight-Frame-Digital-Picture-Anywhere/dp/B01N7ENHO6?maas=maas_adg_B8802455AAB37AC2889373D2524B5153_afap_abs&ref_=aa_maas&tag=maas'

  const videoSrc = HERO_VIDEO_OVERRIDE[page] || heroVideo
  const [videoControls, setVideoControls] = useState(false)

  useEffect(() => {
    if (
      window.navigator.userAgent.indexOf('Mozilla') !== -1 &&
      window.navigator.userAgent.indexOf('Android') !== -1
    ) {
      setVideoControls(true)
    }
  }, [])
  const itemList = [
    t('No app or subscription required'),
    t('Quick 1-minute setup — easy for all ages'),
    t('Effortless touchscreen display'),
  ]

  return (
    <Section>
      <Container>
        <TextBox>
          <YotpoStars textcolor="#444444" linkUrl={BUY_LINK} />
          <Text>
            {title ||
              t('Skylight is a photo frame you can send photos to, and they appear in seconds!')}
          </Text>
          {page === 'greetings' ? (
            <ListItem>
              Preload your loved one’s frame with photos, video and for the first time, e-cards.
            </ListItem>
          ) : (
            <>
              {itemList.map((item, i) => (
                <Item key={i}>
                  <CheckMark image={check.childImageSharp.gatsbyImageData} alt="check mark" />
                  <ListItem>{item}</ListItem>
                </Item>
              ))}
            </>
          )}
          {subheading && <SubHeader>{subheading}</SubHeader>}
          {page === 'amazon' ? (
            <CenteredOnMobile>
              <BuyNowAnchor
                href={AMAZON_LINK}
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => Analytics.track('Clicked to Amazon')}
              >
                {ctaText || t('Buy Now')}{' '}
                <ExternalLink image={link.childImageSharp.gatsbyImageData} alt="external link" />
              </BuyNowAnchor>
            </CenteredOnMobile>
          ) : (
            <CenteredOnMobile>
              <BuyNowLink to={BUY_LINK}>{ctaText || t('Buy Now')}</BuyNowLink>
            </CenteredOnMobile>
          )}
        </TextBox>
        <VideoContainer>
          <HeroVideo playsInline autoPlay muted loop controls={videoControls}>
            <source src={videoSrc} type="video/mp4" />
          </HeroVideo>
        </VideoContainer>
      </Container>
    </Section>
  )
}

Hero.propTypes = {
  page: PropTypes.string,
  title: PropTypes.string,
  subheading: PropTypes.string,
  ctaText: PropTypes.string,
}
export default Hero
