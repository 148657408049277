export const FRAME_FEATURES = [
  {
    headingOne: 'Quick one-minute setup',
    text: 'Plug in and tap the screen to connect to WiFi. ',
  },
  {
    headingOne: 'Send from phone to frame',
    text: `Everyone in the family can send photos with the mobile app or via email, and they arrive in seconds.`,
  },
  {
    headingOne: 'Intuitive touchscreen display',
    text: 'Swipe through photos, browse the gallery, delete photos, or pause the slideshow - all with a simple tap.',
  },
  {
    headingOne: 'New photo alerts',
    text: 'Tap the notification to view new photos.',
  },
  {
    headingOne: '“Heart” photos to say thanks',
    text: `Tap the Heart button and Skylight will tell your friend or family that you love the photo!`,
  },
  {
    headingOne: 'Enjoy the frame without WiFi',
    text: `Skylight requires WiFi to receive new photos, but you can still view your existing photos without a WiFi connection.`,
  },
]
