import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Trans } from 'gatsby-plugin-react-i18next'

import { Section, Container, Text, Card, MoneyBackContainer, Box, FlexBox } from './styles'
import { GUARANTEE_CONSTANTS } from './constants'
import { IS_FOREIGN_COUNTRY } from '../../utils/constants'

const Guarantee = () => {
  const { guaranteeLogo, internationalGuaranteeLogo, moneybackLogo } = useStaticQuery(graphql`
    query GuaranteeQuery {
      guaranteeLogo: file(relativePath: { eq: "baby-walking.webp" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      internationalGuaranteeLogo: file(relativePath: { eq: "daughter-ballerina.webp" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      moneybackLogo: file(relativePath: { eq: "money-back.webp" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
        }
      }
    }
  `)
  const text = GUARANTEE_CONSTANTS.default

  const moneyBack = moneybackLogo
  const guaranteeImage = IS_FOREIGN_COUNTRY ? internationalGuaranteeLogo : guaranteeLogo

  return (
    <>
      <Section>
        <Container flexWrap="wrap-reverse">
          <Card>
            <Box>
              <GatsbyImage
                image={guaranteeImage.childImageSharp.gatsbyImageData}
                alt="Red Skylight Frame with Baby Walking in Photo"
              />
              <FlexBox>
                <MoneyBackContainer>
                  <GatsbyImage
                    image={moneyBack.childImageSharp.gatsbyImageData}
                    alt="Money Back Guarantee"
                  />
                </MoneyBackContainer>
              </FlexBox>
            </Box>
          </Card>
          <Card>
            <Text>
              <Trans>{text}</Trans>
            </Text>
          </Card>
        </Container>
      </Section>
    </>
  )
}

export default Guarantee
